import React, { useState, useEffect } from 'react';
//import PropTypes from 'prop-types';
import { Box } from '@chakra-ui/react';
import styled from 'styled-components';
import { withRouter } from 'react-router';
import EntityService from '../../services/EntityService';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
//import { settingMenuItems, entityMenuItems, personalMenuItems } from '../../constants';

const SidebarBox = styled(Box)`
  width: 280px;
  background: #0055af;
  transition: all 0.5s ease 0s;
  padding: 60px;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
`;

const SideBarItem = styled(Box)`
  color: #fff;
  font-size: 18px;
  padding-bottom: 20px;
  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
`;

function Sidebar(props) {
  const { t } = useTranslation();
  const [entity, setentity] = useState("")
  const history = useHistory();
  const entityId = props.location.pathname.split('/').pop();
  
useEffect(() => {
  if(entityId){
    EntityService.getEntity(entityId).then((res)=>{
      setentity(res)
    }).catch((error)=>{
      console.error(error)
    })
  }
},[entityId]
)

const handleUserlist = () => {
  history.push({
    pathname: '/entity/userlist/' + entityId,
    state: { values: entity }
});
}

const handleEntitylist = () => {
  history.push({
    pathname: '/entity/' + entityId,
    state: { values: entity }
});
}

const handleTeamlist = () => {
  history.push({
    pathname:'/entity/teamlist/' + entityId,
    state: { values: entity }
  })
}

const handleApps = () => {
  history.push({
    pathname:'/entity/apps/' + entityId,
    state: { values: entity }
  })
}

  return (
   <SidebarBox>
        <Box>
        <SideBarItem onClick={handleEntitylist}>{entity && entity.entityName}</SideBarItem>
        <SideBarItem onClick={handleTeamlist}>{t('circlepass.sidebar.teams')}</SideBarItem>
        <SideBarItem onClick={handleUserlist}>{t('circlepass.sidebar.members')}</SideBarItem>
        <SideBarItem onClick={handleApps}>{t('circlepass.sidebar.apps')}</SideBarItem>
        </Box>
        <Box mt="150px" pb="50px">
        <SideBarItem>{t('circlepass.sidebar.account')}</SideBarItem>
        <SideBarItem>{t('circlepass.sidebar.help')}</SideBarItem>
        <SideBarItem>{t('circlepass.sidebar.logout')}</SideBarItem>
        </Box>
  </SidebarBox>
  );
}

Sidebar.propTypes = {};

export default withRouter(Sidebar);
