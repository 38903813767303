import { auth } from "../Firebase/firebase";
import UserService from "./UserService";
import { GoogleAuthProvider,signInWithPopup } from "firebase/auth";

function signInWithGoogle(callback){
    let google_provider = new GoogleAuthProvider();
      signInWithPopup(auth,google_provider)
      .then((res) => { 
       UserService.activateUser(res).then((res)=>{
         if(res !== undefined){
          callback(res)
         }else{
          callback(res)
         }
       }).catch((error)=>{
        console.error(error)
      })
      })
      .catch((err) => {
        console.log(err);
        callback(false)
      });
}
function isLoggedIn(){
   let user =  JSON.parse(sessionStorage.getItem("user"))
   if(user){
       return true
   }
   return false
}
function userLogOut(){
  auth.signOut();
  sessionStorage.removeItem("user");
}

function getCurrentUser(){
  let user = JSON.parse(sessionStorage.getItem("user"))
  if(user){
    return user
}
return null
}

function userHasRole(userrole){
  let role = sessionStorage.getItem("role")
  if(role){
    return role === userrole;
  }
  return false
}

export{
    signInWithGoogle,isLoggedIn,userLogOut,getCurrentUser, userHasRole
}