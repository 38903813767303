import React from 'react';
import { Link, ModalContent, ModalHeader, Text, Image } from '@chakra-ui/react';
import { Modal, ModalOverlay, ModalBody } from '@chakra-ui/react';
import imgButton from '../../../assets/images/Button.png';
import styled from 'styled-components';
import { withRouter } from 'react-router';
import PropTypes from 'prop-types';

const Header = styled(ModalHeader)`
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 23px;
  color: #000000;
  text-align: center;
  margin-top: 87px;
`;

const SignInButtonImage = styled(Image)`
    max-width: 206px;
    margin-top: 118px;
    margin-left: 98px;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    cursor:pointer;
}
`;

const SignInModal = props => {
    const signIn=()=>{
        props.onClose();
        props.history.push('/signin');
    }

  return (
    <Modal isCentered onClose={props.onClose} isOpen={props.isOpen} motionPreset="slideInBottom">
      <ModalOverlay />
      <ModalContent>
        <Header>Welcome Back</Header>
        <ModalBody textAlign="center" justifyContent="center">
          <SignInButtonImage src={imgButton} alt="Sign in with Circle Pass" onClick={signIn}/>
          <Text marginTop="58px">
            Don't have any account?
            <Link>Create one</Link>
          </Text>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
SignInModal.propTypes={
    isOpen:PropTypes.bool.isRequired,
    onClose:PropTypes.func.isRequired
}
export default withRouter(SignInModal);
