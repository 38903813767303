import React,{useEffect,useState} from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {
  Link,
  Flex,
  Spacer,
  Image,
  Box,
  Menu,
  MenuButton,
  MenuItem,
  MenuList as ChakraMenuList,
  Text,
  Button,
  Center,
  useDisclosure,
  useToast
} from '@chakra-ui/react';
import imgCircle from '../../assets/images/CirclePass.png';
//import userLogo from '../../assets/images/Aetna Icon 0.png';
import imgAkar from '../../assets/images/akar-icons_circle-plus.png';
import { ChevronDownIcon } from '@chakra-ui/icons';
import { withRouter, useHistory } from 'react-router';
import SignInModal from './subComponents/SignInModal';
import EntityService from '../../services/EntityService';
import googleIcon from '../../assets/images/google.png';
import { getCurrentUser, isLoggedIn, signInWithGoogle,userLogOut } from '../../services/AuthenticationService'
import { useTranslation } from 'react-i18next';

const HeaderBox = styled(Box)`
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  padding: 20px 40px;
  zindex: 99;
  height: 95px;
  width: 100%;
`;
const LoggedUserBox = styled(Box)`
  margin-left: 50px;
  display: inline-flex;
  -ms-flex-align: center;
  align-items: center;`;

const MenuList = styled(ChakraMenuList)`
  width: 350px;
  margin-top: 7px;
  box-shadow: 5px 20px 40px -2px rgba(40, 40, 40, 0.20) !important;
  border-radius: 5px !important;
  font-size: 16px;
  letter-spacing: 0.02em;
`
const EntityImage = styled(Image)`
  border-radius: 50%;
  cursor: pointer;
  margin-top: 3px`;

const EntityName = styled(Box)`
  margin-top: 10px;
  margin-left: 25px;
  font-family: Roboto;
  font-size: 24px;
  font-weight: bold`;

const EntityImageList = styled(Image)`
  border-radius: 50%;
  margin-right: 12px;`;

const Profile = styled(MenuItem)`
  padding: 8px`;

const UserMenuButton = styled(Button)`
  background-color: transparent !important ;
  cursor: pointer;
`;

const UserMenuBox = styled(Box)`
  display: flex;
  align-items: center;
  padding: 12px 16px;
  margin-right: 20px;`;

const UserImage = styled(Image)`
  height: 40px;
  width: 40px;
  cursor: pointer;
  border-radius: 50%;
`
const UsertopImage = styled(Image)`
cursor: pointer;
width: 45px;
border-radius: 50%;
`

function Header(props) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [user, setuser] = useState({});
  const [entityList, setentityList] = useState([]);
  const [entity, setentity] = useState("")
  const history = useHistory();
  const toast = useToast();
  const {replace} = useHistory();
  const { t } = useTranslation();
  const entityId = props.location.pathname.split('/').pop();

  useEffect(() => {
    const currentUser = getCurrentUser();
    setuser(currentUser);
    if(currentUser){
      EntityService.getEntities(currentUser.memberEmail).then((res) => {
        setentityList(res);
        if(entityId && res.length>0){
         const selectedEntity = res.find((entity)=>entity.id=== entityId)
          if(selectedEntity){
            setentity(selectedEntity);
          }
        }
      }).catch((error)=>{
        console.log(error)
      });
    }
  }, [entityId]
  );

  const handleLogOut=()=>{
    userLogOut()
    setentity("")
    props.history.push('/');
  }
  // const changeUrl=(url)=>{
  //   props.handleRouteChangeCallback(url)
  //   props.history.push(url)
  // }
  const createEntity = () => {
    history.push('/createentity')
  }
  const handleEntityHome = () => {
    history.push('/entityhome')
  }

   const handleEntityChange =(e) =>{
     debugger
    const found = entityList.find( result  => (result.entityName === e.innerText))
      if(found && found.id !== undefined){
        replace("/entity/"+ found.id)
      }
  }
  const SigninwithFirebase = () => {
    signInWithGoogle((user)=>{
      if(user === false){
        toast({
          title: "Suspended.",
          description: "You are suspended Contact to Administrator",
          status: "error",
          duration: 3000,
          position: "top",
          isClosable: true,
        });
      }
     if(user.updateTeamMember){
        sessionStorage.setItem("user", JSON.stringify(user.updateTeamMember))
     }
     if(user.createTeamMember){
      sessionStorage.setItem("user", JSON.stringify(user.createTeamMember))
      }
      if(!user.updateTeamMember && !user.createTeamMember && user){
        sessionStorage.setItem("user", JSON.stringify(user))
      }
      history.push("/home")
     }
    )
  };

  return (
    <HeaderBox>
    <SignInModal isOpen={isOpen} onOpen={onOpen} onClose={onClose}/>
      <Flex>
      { isLoggedIn() && entity ? 
        <LoggedUserBox>
            <Menu>
              <MenuButton>
              <Flex>
                <EntityImage src={entity && entity.entityLogo && entity.entityLogo.url} boxSize="50px"/>
                <EntityName>{entity && entity.entityName}</EntityName>
                <ChevronDownIcon m="15px"/>
              </Flex>
              </MenuButton>
              <MenuList maxH="400px" overflowx="none">
                <MenuItem onClick={createEntity}>
                <Flex ml="13px">
                  <EntityImageList boxSize='2.5rem' src={imgAkar} alt='Logo'/>
                    <Text mt="10px">{t('circlepass.create.entity.title')}</Text>
                </Flex>
                </MenuItem>
                <hr></hr>
                <Box padding="12px 16px">
                {entityList.map(result => (
                  <MenuItem value={result.id} onClick={(e)=>handleEntityChange(e.target)}> 
                  <Flex>
                  <EntityImageList boxSize='2rem' src={result.entityLogo.url} alt='Logo'/>
                  <Text pt="5px"> {result.entityName} </Text>
                  </Flex></MenuItem>))}
                </Box>
              </MenuList>
            </Menu>
        </LoggedUserBox> 
      :
        <Box mt="-15px">
          <Link size="md" onClick={()=>props.history.push("/")}>
            <Image src={imgCircle} marginLeft="25px" width="258.06px" />
          </Link>
        </Box>
      }
        <Spacer />
        {!isLoggedIn() && (
          <Box>
          <Box w="20%">
         <Button mt="8px" p="20px" variant={"outline"} onClick={SigninwithFirebase} leftIcon={<Image boxSize="25px" src={googleIcon} />}>
           <Center><Text fontSize="18px">{t('circlepass.landing.page.signin')}</Text></Center>
         </Button>
       </Box>
          </Box>
        )}
        {isLoggedIn() && (
          <LoggedUserBox>
            <Menu>
              <MenuButton as={UserMenuButton} className="icon-dwn" rightIcon={<ChevronDownIcon />}>
                <UsertopImage src={user && user.mamberImage} />
              </MenuButton>
              <MenuList>
                <UserMenuBox>
                  <UserImage src={user && user.mamberImage} />
                  <Text ml="20px">
                    <Text>{user && user.memberName}</Text>
                    <span>{user && user.memberEmail}</span>
                  </Text>
                </UserMenuBox>
                <Box p="2px 16px"><hr></hr>
                  <Profile>{t('circlepass.header.myprofile')}</Profile>
                  <Profile onClick={handleEntityHome}>{t('circlepass.header.myentitylist')}</Profile>
                  <Profile >{t('circlepass.header.settings')}</Profile>
                  <Profile>{t('circlepass.header.help')}</Profile>
                  <Profile onClick={() =>handleLogOut()}><span>{t('circlepass.header.logout')}</span></Profile>
                </Box>
              </MenuList>
            </Menu>
          </LoggedUserBox>
        )}
      </Flex>
    </HeaderBox>
  );
}

Header.propTypes = {
  loggedIn: PropTypes.bool.isRequired,
};

export default withRouter(Header);
