import {GraphQLClient} from "graphql-request";
import axios from "axios";


const {REACT_APP_GRAPH_CMS_CONTENT_API_KEY, REACT_APP_GRAPH_CMS_TOKEN_KEY, REACT_APP_GRAPH_CMS_ASSET_API_KEY} =
    process.env;

const authorizationKey = `Bearer ${REACT_APP_GRAPH_CMS_TOKEN_KEY}`;
const graphCMSAPIKey = REACT_APP_GRAPH_CMS_CONTENT_API_KEY;

class Service {
    constructor() {
        this.graphcms = new GraphQLClient(graphCMSAPIKey, {
            headers: {
                authorization: authorizationKey,
            }
        });
    }

    async getallEntities() {
        const {entities} = await this.graphcms.request(
            `query MyQuery {
        entities{
          id
          entityName
          entityDescription
          entityEmail
          entityWebsite
          entityType
          servicesProvided
          entitySize
          entityHeadquarter
          entityUrl
          entityLogo {
            id
            url
            fileName
          }
          entityProfilePicture {
            id
            url
            fileName
          }
        }
      }`);
        return entities;
    }

    async getEntities(mail) {
        const {entities} = await this.graphcms.request(
            `query EntitiesbyEmail {
          entities(
            where: {teamMembers_some: {memberEmail: "${mail}"}}
          ) {
            id
            entityName
            entityDescription
            entityEmail
            entityWebsite
            entityType
            servicesProvided
            entitySize
            entityHeadquarter
            entityUrl
            entityLogo {
              id
              url
              fileName
            }
            entityProfilePicture {
              id
              url
              fileName
            }
          }
        }
        `
        );
        return entities;
    }

    async getEntity(entityId) {
        const {entity} = await this.graphcms.request(`query MyQuery {
          entity (where: {id: "${entityId}"}) {
            id
            entityName
            entityDescription
            entityEmail
            entityWebsite
            entityType
            servicesProvided
            entitySize
            entityHeadquarter
            entityUrl
            entityLogo {
              id
              url
              fileName
            }
            entityProfilePicture{
              id 
              url
              fileName
            }
          }
        }`);
        return entity;
    }

    async addEntity(data) {
        return await this.graphcms.request(`mutation ($data: EntityCreateInput!) {
            createEntity(data: $data){id}
          }`, {
            data: {
                entityName: data.entityName,
                entityDescription: data.entityDescription,
                entityEmail: data.entityEmail,
                entityWebsite: data.entityWebsite,
                entityType: data.entityType,
                servicesProvided: data.servicesProvided,
                entitySize: parseInt(data.entitySize),
                entityHeadquarter: data.entityHeadquarter,
                entityUrl: data.entityUrl,
                entityLogo: {
                    connect: {
                        id: data.logoId
                    }
                },
                teamMembers: {
                    connect: [{
                        id: data.teamMemberId
                    }]
                }
            }
        });
    }

    async UpdateEntity(data, id) {
        return await this.graphcms.request(`mutation ($data: EntityUpdateInput!, $id: ID!) {
        updateEntity(where:{id: $id}, data: $data){
          id
          entityName
        }
          }`, {
            id: id,
            data: {
                entityName: data.entityName,
                entityDescription: data.entityDescription,
                entityEmail: data.entityEmail,
                entityWebsite: data.entityWebsite,
                entityType: data.entityType,
                servicesProvided: data.servicesProvided,
                entitySize: parseInt(data.entitySize),
                entityHeadquarter: data.entityHeadquarter,
                entityUrl: data.entityUrl
            }
        });
    }

    async uploadAsset(logo, callback) {
        const formData = new FormData();
        formData.append("fileUpload", logo);
        axios.post(REACT_APP_GRAPH_CMS_ASSET_API_KEY, formData, {
            headers: {
                Authorization: authorizationKey,
                "Content-Type": "application/x-www-form-urlencoded"
            }
        }).then((response) => {
            return callback(response, null);
        }).catch((error) => {
            return callback(null, error);
        });
    }
}

export default new Service();

