import { GraphQLClient } from "graphql-request";

const {REACT_APP_GRAPH_CMS_CONTENT_API_KEY, REACT_APP_GRAPH_CMS_TOKEN_KEY} =
    process.env;

const authorizationKey = `Bearer ${REACT_APP_GRAPH_CMS_TOKEN_KEY}`;
const graphCMSAPIKey = REACT_APP_GRAPH_CMS_CONTENT_API_KEY;


class Service {
  constructor() {
    this.graphcms = new GraphQLClient(graphCMSAPIKey, {
        headers: {
            authorization: authorizationKey,
        }
    });
}
  async getUserbyEntity(data) {
    const { teamMembers } = await this.graphcms.request(
      `query MyQuery {
        teamMembers(where: {entity_some: {id: "${data.id}"}}) {
          id
          memberName
          memberEmail
          createdAt
          loginStatus
          suspendedStatus
          mamberImage
          teamProfile (where: {entity: {id: "${data.id}"}}){
            id
            teamName
          }
          entityRole (where: {entities_some: {id: "${data.id}"}}){
            id
            memberRole
          }
          teamRole{
            id
            teamRole
          }
        }
      }`
    );
    return teamMembers;
  }
  async addTeamMember(data) {
    const { teamMembers } = await this.graphcms.request(
      `query ListTeamMembers {
        teamMembers {
          id
          memberName
          memberEmail
          loginStatus
          mamberImage
          suspendedStatus
          entityRole {
            memberRole
          }
        }
      }`
    );

    let user = teamMembers.find(
      (member) => ((member.memberEmail === data.memberEmail))
    );
    if(user){
      const { updateTeamMember } = await this.graphcms.request(
      `mutation MyMutation {
        updateTeamMember(data: {entity: {connect: {where: {id: "${data.props.location.state.values.id}"}}}}, where: {id: "${user.id}"}) {
          id
          memberName
        }
      }`
      );
      return updateTeamMember
    }else{
      return await this.graphcms.request(
        `mutation ($data: TeamMemberCreateInput!) {
          createTeamMember(data: $data){id}
            }`,
        {
          data: {
            entity: { connect: [{ id: data.props.location.state.values.id }] },
            loginStatus: false,
            suspendedStatus: false,
            memberName: data.memberName,
            memberEmail: data.memberEmail,
          },
        }
      );
    }
  }

  async UpdateTeamMember(data){
    return await this.graphcms.request(
      `mutation MyMutation {
        updateTeamMember(
          data: {entityRole: {connect: {where: {id: "${data.res.createEntityRole.id}"}}}}
          where: {id: "${data.memberId}"}
        ) {
          id
        }
      }`
    )
  }

  async addTeamMemberRole(data) {
    return await this.graphcms.request(
      `mutation ($data: MemberRoleCreateInput!) {
        createMemberRole(data: $data){id}
          }`,
      {
        data: {
          entity: { connect: { id: data.entityid } },
          memberRole: [data.memberrole],
          teamMember: { connect: { id: data.memberId.id } },
        },
      }
    );
  }
 async updateUserEntityRole(data) {
   return await this.graphcms.request(
     `mutation ($data: EntityRoleUpdateInput!) {
      updateEntityRole( data: $data, where: {id: "${data.roleId}"}){
        id
      }
    }`,
    {
      data: {memberRole: [data.entityRole]}
    }
   );
 }


  async addTeamRole(role,team,user) {
    return await this.graphcms.request(
      `mutation ($data: TeamRoleCreateInput!) {
        createTeamRole(data: $data){id}
          }`,
      {
        data: {
          teamProfiles: { connect: [{id:team}] },
          teamRole: role,
          teamMembers: { connect: [{id:user}] },
        },
      }
    );
  }

  async updateTeamProfileDisConnectUser(team, member, teamrole) {
    const {updateTeamProfile} =  await this.graphcms.request(
      `mutation updateTeamProfileDisConnectUser {
        updateTeamProfile(
          where: {id: "${team}"}
          data: {teamMembers: {disconnect: {id: "${member}"}}, teamRole: {disconnect: {id: "${teamrole}"}}}
        ) {
          id
        }
      }
      `
    );
    return updateTeamProfile;
  }

  async removeTeamRole(teamrole){
    return await this.graphcms.request(
      `mutation deleteTeamRole {
        deleteTeamRole(where: {id: "${teamrole}"}) {
          id
        }
      }
      `
    )
  }

  async UpdateTeamRole(teamrole, teamroleId){
    return await this.graphcms.request(
      `mutation updateTeamRole {
        updateTeamRole(data: {teamRole: ${teamrole}}, where: {id: "${teamroleId}"}) {
          id
        }
      }`
    )
  }

  async addEntityRole(data, memberId) {
    return await this.graphcms.request(
      `mutation ($data: EntityRoleCreateInput!) {
        createEntityRole(data: $data){id}
          }`,
      {
        data: {
          entities: { connect: [{ id: data.props.location.state.values.id }] },
          teamMembers: { connect: [{ id: memberId }] },
          memberRole: [data.memberRole]
        },
      }
    );
  }

  async getCurrentRoleofUser(data) {
    const {entityRoles} =  await this.graphcms.request(
      `query MyQuery {
        entityRoles(
          where: {teamMembers_some: {id: "${data.user}"}, AND: {entities_some: {id: "${data.entity}"}}}
        ) {
          id
          memberRole
        }
      }`
    );
    return entityRoles;
  }

  async getuserList() {
    const {teamMembers} =  await this.graphcms.request(
      `query MyQuery {
        teamMembers {
          id
          memberEmail
        }
      }
      `
    );
    return teamMembers;
  }
  async suspendTeamMember(data) {
    return await this.graphcms.request(
      `mutation MyMutation {
        updateManyTeamMembers(
          data: {suspendedStatus: true}
          where: {memberEmail: "${data}"}
        ){
          count
        }
      }`,
    );
  }
  async activeTeamMember(data) {
    return await this.graphcms.request(
      `mutation MyMutation {
        updateManyTeamMembers(
          data: {suspendedStatus: false}
          where: {memberEmail: "${data}"}
        ){
          count
        }
      }`,
    );
  }

  async activateUser(data) {
    const { teamMembers } = await this.graphcms.request(
      `query ListTeamMembers {
        teamMembers {
          id
          memberName
          memberEmail
          loginStatus
          mamberImage
          suspendedStatus
          entityRole {
            memberRole
          }
        }
      }`
    );
    let user = teamMembers.find(
      (member) => ((member.memberEmail === data.user.email))
    );
    let Checkuser = teamMembers.filter(
      (member) => ((member.memberEmail === data.user.email))
    );
    let IdentifiedUser = Checkuser.find((member) => ((member.loginStatus===false && member.mamberImage===null)))
    // if (user && user.memberRole[0].memberRole[0] !== "Account_Holder") {
      if(user && user.suspendedStatus) {
        return false;
      }
      if(IdentifiedUser && !IdentifiedUser.suspendedStatus) {
        const graphcms = new GraphQLClient(
          graphCMSAPIKey,
          {
            headers: {
              authorization: authorizationKey,
            },
          }
        );
        const LogInuser = await graphcms.request(
          `mutation MyMutation {
              updateTeamMember(
                data: 
                  {
                    loginStatus: true
                    mamberImage: "${data.user.photoURL}"
                  },
                   where: 
                    {id: "${IdentifiedUser.id}"})
             {
                id
                memberEmail
                loginStatus
                mamberImage
                memberName
              }
            }`
        );
        return LogInuser;
      }else if(!user){
        const graphcms = new GraphQLClient(
          graphCMSAPIKey,
          {
            headers: {
              authorization: authorizationKey,
            },
          }
        );
        const LogInuser = await graphcms.request(
          `mutation ($data: TeamMemberCreateInput!) {
            createTeamMember(data: $data){
              id
              memberEmail
              loginStatus
              mamberImage
              memberName
            }
              }`,
          {
            data: {
              memberName: data.user.displayName,
              memberEmail: data.user.email,
              loginStatus: true,
              mamberImage: data.user.photoURL,
              suspendedStatus: false
            },
          }
        );
        return LogInuser;
      }else{
        return user;
      }
    
    // } else {
    //   return user;
    // }
  }

  async updateToAdmin(data) {
    return await this.graphcms.request(
      `mutation MyMutation {
        createEntityRole(
          data: {teamMembers: {connect: {id: "${data.teamMemberId}"}}, entities: {connect: {id: "${data.entityId.id}"}}, memberRole: Admin}
        ) {
          id
        }
      }
      `
    );
  }

  async DeleteTeamMember(data) {
    return await this.graphcms.request(
      `mutation ($ids: [ID!]!) {
        deleteManyTeamMembersConnection(where: {id_in: $ids}) 
          {
              aggregate{count}
          }}`,
      { ids: [data] }
    );
  }

}

export default new Service();
