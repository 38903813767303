import React, { Suspense ,useState, useEffect} from 'react';
import { ChakraProvider, Box, Grid } from '@chakra-ui/react';
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom';
import { LandingPage,HomePage, CreateEntityPage, ConfirmEntityPage, EntityHomePage, EntityDashBoardPage, TeamListPage, UserListPage, AppsPage, CloseAccountPage,NonExistingPage} from './routes';
import Header from './components/Header/Header';
import Sidebar from './components/Sidebar/Sidebar';
import { isLoggedIn } from './services/AuthenticationService'
import { initReactI18next } from "react-i18next";
import axios from "axios";
import i18n from "i18next";

function App() {
  // const [signedIn, setsignedIn] = useState(false);
   const [currentRoute,setCurrentRoute]=useState("");
  const sideBarRequiredPages=["/closeaccount","/updateaccount"]

  // useEffect(() => {
  //   setsignedIn(Boolean(localStorage.getItem("isSignedIn")))
  // }, [])
  // const onSignIn=()=>{
  //   setsignedIn(Boolean(localStorage.getItem("isSignedIn")))
  // }
  const handleRouteChangeCallback=(route)=>{
    setCurrentRoute(route)
    //onSignIn()
  }
  const [resource, setresource] = useState("");
  const [fetched, setFetched] = useState(false);

  useEffect(() => {
    axios
      .get("https://jqdryvpiqf.execute-api.us-east-2.amazonaws.com/default/i18reader")
      .then((response) => {
        setresource(response.data)
        setFetched(true)
      })
      .catch((error) => {
        console.log(error)
      });
  }, [])

  if (fetched) {
    i18n
      .use(initReactI18next)
      .init({
        resources: {
          en: {
            translation: {
              ...resource.translation
            }
          }
        },
        lng: "en",
        interpolation: {
          escapeValue: false
        }
      });
  }
  return (
    fetched ? 
    <ChakraProvider>
      <Box fontSize="xl">
        <Grid>
          <Router>
          <Header currentroute={currentRoute} loggedIn={isLoggedIn()} handleRouteChangeCallback={handleRouteChangeCallback}/> 
          <Box display="flex">
          {isLoggedIn() && sideBarRequiredPages.includes(currentRoute) && <Sidebar/> }
            <Suspense fallback={<div>Loading...</div>}>
              <Switch>
                <Route exact path="/" render={()=><LandingPage/>} />
                {/* <Route exact path="/signin" render={()=><SignInPage signedInCallback={onSignIn}/>}/> */}
                <Route exact path="/home" render={()=> isLoggedIn() ? <HomePage/> : <Redirect to={"/"}/>} />
                <Route exact path="/createentity" render={()=> isLoggedIn() ? <CreateEntityPage/> : <Redirect to={"/"} />} />
                <Route exact path="/editentity/:entityId" render={()=> isLoggedIn() ? <CreateEntityPage/> : <Redirect to={"/"} />} />
                <Route exact path="/confirmdetail" render={()=> isLoggedIn() ? <ConfirmEntityPage/> : <Redirect to={"/"} />} />
                <Route exact path="/entityhome" render={()=> isLoggedIn() ? <EntityHomePage/> : <Redirect to={"/"} />} />
                <Route exact path="/entity/:entityId" render={()=> isLoggedIn() ? <EntityDashBoardPage/> : <Redirect to={"/"} />} />
                <Route exact path="/entity/teamlist/:entityId/" render={()=> isLoggedIn() ? <TeamListPage/> : <Redirect to={"/"} />} />
                <Route exact path="/entity/userlist/:entityId/" render={()=> isLoggedIn() ? <UserListPage/> : <Redirect to={"/"} />} />
                <Route exact path="/entity/apps/:entityId/" render={()=> isLoggedIn() ? <AppsPage/> : <Redirect to={"/"} />} />
                <Route exact path="/closeaccount" render={()=> isLoggedIn() ? <CloseAccountPage/> : <Redirect to={"/"}/>}/>
                <Route render={()=><NonExistingPage/>}/>
              </Switch>
            </Suspense>
            </Box>
          </Router>
        </Grid>
      </Box>
    </ChakraProvider>
    :
    <Box>
      Loading
    </Box>
  );
}

export default App;
