import { lazy } from 'react';
const LandingPage = lazy(() => import('../pages/LandingPage'));
const HomePage=lazy(()=>import('../pages/HomePage'));
const SignInPage = lazy(()=>import('../pages/SignInPage'));
const CreateEntityPage = lazy (()=>import('../pages/CreateEntityPage'))
const ConfirmEntityPage = lazy (()=>import('../pages/ConfirmEntityPage'))
const EntityHomePage = lazy (()=>import('../pages/EntityHomePage'))
const EntityDashBoardPage = lazy(()=> import('../pages/EntityDashboardPage'))
const TeamListPage = lazy(()=>import('../pages/TeamListPage'))
const UserListPage = lazy(()=>import('../pages/UserListPage'))
const AppsPage = lazy(()=>import('../pages/AppsPage'))
const CloseAccountPage = lazy(()=>import('../pages/CloseAccountPage'));
const NonExistingPage = lazy(()=>import('../pages/NonExistingPage'));
    
export {
    LandingPage,HomePage,SignInPage,CloseAccountPage,NonExistingPage,CreateEntityPage,ConfirmEntityPage,EntityHomePage,TeamListPage,EntityDashBoardPage,UserListPage,AppsPage
}
